import posthog from "posthog-js";
import { TrackFunction } from "./events";

export const track: TrackFunction = (eventName, props) => {
  posthog.capture(eventName, props);
};

export const identify = (uid: string) => {
  posthog.identify(uid);
};
